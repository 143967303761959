import get from '@invitato/helpers/dist/getLocale';

export default {
  title: get('Siaran<br />Langsung', 'Live<br />Streaming'),
  countingTitle: get('Counting<br />Days', 'Counting<br />Days'),
  open: get('Buka via Youtube', 'Open via Youtube'),
  desc: get(
    `Siaran langsung juga dapat disaksikan <br />melalui platform YouTube:`,
    `Live Streaming can also be watched <br />via the YouTube platform:`,
  )
};